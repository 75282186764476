import { navigate, type HeadFC } from 'gatsby';
import { Box, desktopFirstMediaQuery, Flex } from '@inflearn/ds-react';
import { css } from '@emotion/react';
import { useDisclosure } from '@mantine/hooks';
import { isObject } from 'lodash-es';
import { useEffect } from 'react';

import Scripts from '../../components/Scripts';
import Seo from '../../utils/Seo';
import { URLS } from '../../utils/urls';
import RallitMissionSection from '../../components/infcon-2024/MyPage/RallitHubMission/RallitMissionSection';
import ScheduleMissionSection from '../../components/infcon-2024/MyPage/ScheduleMission/ScheduleMissionSection';
import MyInfconSection from '../../components/infcon-2024/MyPage/MyInfcon/MyInfconSection';
import FloatingSection, { ShareButton } from '../../components/infcon-2024/MyPage/FloatingSection';
import useV2Session from '../../hooks/useV2Session';
import LoginRequestModal from '../../components/infcon-2023/sessionDetail/LoginRequestModal';
import ApplicationMissionSection from '../../components/infcon-2024/MyPage/ApplicationMission/ApplicationMissionSection';
import { GATSBY_CDN_URL, GATSBY_SITE_URL } from '../../utils/env';
import { useViewInfconMain } from '../../hooks/useViewInfconMain';
import { useMission } from '../../components/infcon-2024/MyPage/MyInfcon/useMission';
import { getCdnUrl } from '../../utils/getCdnUrl';

export const Head: HeadFC = () => {
  return (
    <>
      <Seo
        title="인프콘 2024 - MY페이지"
        description="미션을 수행하며 올해의 인프콘을 준비해보세요"
        fullUrl={`${GATSBY_SITE_URL}/conf${URLS.INFCON_2024__MYPAGE}`}
        image={getCdnUrl('infcon-2024-main/official-og.png')}
      />
      <Scripts />
    </>
  );
};

export default function Infcon2024Page() {
  const { data: sessionData, isError } = useV2Session();
  const [isOpenLoginModal, { open: openLoginModal, close: closeLoginModal }] = useDisclosure(false);
  useViewInfconMain();

  const handleModalClose = () => {
    closeLoginModal();

    if (typeof window === 'undefined') {
      return;
    }

    void navigate(URLS.INFCON_2024__HOME);
  };

  useEffect(() => {
    if (sessionData === null) {
      return;
    }

    if (!isObject(sessionData?.data) && isError) {
      openLoginModal();
    }
  }, [sessionData, isError]);

  const { missionCount, completedCount, isAllClear } = useMission();

  return (
    <>
      <Box w="100%" bg="#0086CD" css={styleBanner} />
      <Flex direction="column" justify="center" align="center" bg="gray.0" w="100%" h="100%">
        <Flex direction="column" css={styleMyPage}>
          <MyInfconSection
            missionCount={missionCount}
            completedCount={completedCount}
            isAllClear={isAllClear}
          />
          <ApplicationMissionSection />
          <RallitMissionSection />
          <ScheduleMissionSection />
        </Flex>
        <FloatingSection>
          <ShareButton missionStep={completedCount} />
        </FloatingSection>
      </Flex>
      <LoginRequestModal isOpen={isOpenLoginModal} onClose={handleModalClose} required />
    </>
  );
}

const styleBanner = css({
  height: 160,
  backgroundImage: `url("${GATSBY_CDN_URL}/infcon/infcon-2024/infcon2024-banner-pc.png")`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',

  [desktopFirstMediaQuery.mobile]: {
    height: 200,
    backgroundImage: `url("${GATSBY_CDN_URL}/infcon/infcon-2024/infcon2024-banner-mo.png")`
  }
});

const styleMyPage = css({
  width: 752,
  gap: 32,
  padding: '40px 32px',

  [desktopFirstMediaQuery.tablet]: {
    width: '100%',
    padding: '40px 16px'
  },

  [desktopFirstMediaQuery.mobile]: {
    padding: '40px 16px 0'
  }
});
