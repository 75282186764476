import { filter, isObject, zip } from 'lodash-es';

import useV2Session from '../../../../hooks/useV2Session';
import { useApplicationMission } from '../ApplicationMission/useApplicationMission';
import { useRallitHubMission } from '../RallitHubMission/useRallitHubMission';
import { useScheduleMission } from '../ScheduleMission/useScheduleMission';

const MISSIONS = [
  '로그인 하기',
  '인프콘 참가 신청하기',
  '랠릿 이력서 연결하기',
  '내 시간표 만들기'
];

export const useMission = () => {
  const { data: sessionData } = useV2Session();
  const { isApplication } = useApplicationMission();
  const { hasRallitHub } = useRallitHubMission();
  const { hasSchedule } = useScheduleMission();

  const isLogin = isObject(sessionData?.data);
  const completes = [isLogin, isApplication, hasRallitHub, hasSchedule];

  const completedCount = filter(completes).length;

  return {
    missionCount: MISSIONS.length,
    completedCount,
    missionCompletes: zip(MISSIONS, completes),
    isAllClear: completedCount === MISSIONS.length
  };
};
