import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import {
  COLOR_PALETTE,
  desktopFirstMediaQuery,
  Divider,
  Flex,
  Icon,
  RingProgress,
  Text,
  Tooltip
} from '@inflearn/ds-react';
import { useDisclosure } from '@mantine/hooks';
import { useState } from 'react';

import { INFCON_2024_COLOR_PALETTE } from '../../../../styles/color';
import MyPageBox from '../MyPageBox';
import MissionModal from './MissionModal';

type Props = {
  missionCount: number;
  completedCount: number;
  isAllClear: boolean;
};

const MyInfconSection = ({ missionCount, completedCount, isAllClear }: Props) => {
  const [isOpen, { open: openMissionModal, close: closeMissionModal }] = useDisclosure(false);
  const [isTooltipOpened, setIsTooltipOpened] = useState(false);

  return (
    <>
      <MyPageBox
        onMouseEnter={() => {
          setIsTooltipOpened(true);
        }}
        onMouseLeave={() => {
          setIsTooltipOpened(false);
        }}
        p={24}
        css={{
          cursor: 'pointer',
          marginTop: -120,

          ':hover': {
            borderColor: COLOR_PALETTE.blue[5]
          }
        }}
        onClick={openMissionModal}>
        <Flex direction="column">
          <Text
            fw={700}
            c="gray.9"
            css={{ fontSize: 32, [desktopFirstMediaQuery.mobile]: { fontSize: 24 } }}>
            MY 인프콘
          </Text>
          <Text size={16} fw={500} c="gray.6">
            미션을 수행하며 올해의 인프콘을 준비해보세요.
          </Text>
        </Flex>
        <Tooltip
          label="미션"
          opened={isTooltipOpened}
          withArrow
          position="bottom"
          styles={{ tooltip: { [desktopFirstMediaQuery.mobile]: { display: 'none' } } }}>
          <Flex>
            <RingProgress
              size={98}
              sections={[
                {
                  value: (completedCount / missionCount) * 100,
                  color: isAllClear ? 'grape.6' : 'blue'
                }
              ]}
              label={
                <Text c="gray.9" size={14} fw={500} ta="center">
                  {completedCount}/{missionCount}
                </Text>
              }
            />
          </Flex>
        </Tooltip>
      </MyPageBox>
      <Divider
        size="xs"
        orientation="horizontal"
        labelPosition="center"
        color="gray.3"
        label={
          <Flex gap={8} align="center">
            <Text size="md" c="gray.5">
              mission
            </Text>
            <Icon
              icon={faCircleCheck}
              color={isAllClear ? INFCON_2024_COLOR_PALETTE.blue : 'gray.5'}
            />
          </Flex>
        }
      />
      <MissionModal isOpen={isOpen} onClose={closeMissionModal} />
    </>
  );
};

export default MyInfconSection;
