import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { Modal, Text, Flex, Icon, RingProgress, Button } from '@inflearn/ds-react';

import { useMission } from './useMission';

const MissionModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const { missionCompletes, missionCount, completedCount, isAllClear } = useMission();

  return (
    <Modal.Root id="infcon-2024-mission" opened={isOpen} onClose={onClose} closeOnClickOutside>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Text size={18} fw={700}>
            인프콘 미션
          </Text>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <Text size={16} c="gray.7" mb={16}>
            다음 도전과제를 달성해 보세요.
            <br />
            인프콘 당첨 확률이 높아진다는 소문이 있어요!
          </Text>
          <Flex bg="gray.0" justify="space-between" p={16} css={{ borderRadius: 8 }}>
            <Flex direction="column" gap={4}>
              {missionCompletes.map(([mission, isComplete]) => (
                <Flex key={mission} align="center" gap={8}>
                  <Icon size={12} icon={faCircleCheck} color={isComplete ? 'blue.5' : 'gray.5'} />
                  <Text size={14} c={isComplete ? 'gray.9' : 'gray.6'}>
                    {mission}
                  </Text>
                </Flex>
              ))}
            </Flex>
            <RingProgress
              size={98}
              sections={[
                {
                  value: (completedCount / missionCount) * 100,
                  color: isAllClear ? 'grape.6' : 'blue'
                }
              ]}
              label={
                <Text c="gray.9" size={14} fw={500} ta="center">
                  {completedCount}/{missionCount}
                </Text>
              }
            />
          </Flex>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="filled" color="blue" size="md" onClick={onClose}>
            확인
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};

export default MissionModal;
